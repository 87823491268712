<template>
  <a-modal title="资料列表" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <div class="c-main">
            <div class="clearfix table-tools">
                <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-button type="primary" icon="plus" @click="showEditModal()">添加资料</a-button>
                    </a-form-item>
                </a-form>
                </div>
                <div class="search">
                <a-form layout='inline'  v-if="selectedRowKeys.length == 0" @submit="getList">
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>资料名称</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.file_name" allowClear placeholder="资料名称" style="width: 120px"/>
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>资料类型</span>
                        </template>
                        <a-select v-model="searchParams.search.file_ext_type" allowClear placeholder="资料类型" style="width: 120px">
                        <a-select-option v-for="(item, index) of C_ITEMS.fileExtTypes" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>文件状态</span>
                        </template>
                        <a-select v-model="searchParams.search.file_status" allowClear placeholder="文件状态" style="width: 120px">
                        <a-select-option v-for="(item, index) of C_ITEMS.fileStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button type="primary" html-type="submit" @click="getList()">搜索</a-button>
                    </a-form-item>
                </a-form>
                <a-form layout='inline' v-else>
                    <a-form-item>
                        已选择<span>{{selectedRowKeys.length}}</span>项
                    </a-form-item>
                    <a-form-item>
                        <a-divider type="vertical" />
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="showTransfer" icon="apartment">转移</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>
            <div class="zl-list">
            <div>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                    :columns="columns" :dataSource="list" @change="handleChange"
                    :selection="{key: 'id'}" :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">

                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="name" slot-scope="text,record">
                        <a @click="toPreview(record)" href="javascript:;">{{record.file_name}}</a>
                    </template>

                    <template slot="action" slot-scope="text,record,index">
                        <div style="position: relative;">
                            <a href="javascript:;" @click="showSort(index)">排序</a>
                    
                            <div v-if="isSortModal && sortIndex == index" class="zlgl-sort">
                                <span style="margin-right:5px">排列至第</span>
                                <a-input-number :value="record.file_sort" @change="e=>handleSort(e,record)" :min="1"/>
                                <span style="margin-left:5px">位</span>
                                <a-button @click="handlesortOk(record)" :loading="confirmLoading" style="margin-left:5px" type="primary">确认</a-button>
                                <a-button @click="hideRelatedModal" style="margin-left:5px">取消</a-button>
                            </div>
                            <a-divider type="vertical" />

                            <a-dropdown>
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                                <a-menu slot="overlay">
                                <a-menu-item key="1" >
                                    <a :href="record.link">下载</a>
                                </a-menu-item>
                                <a-menu-item key="2" @click="showEditModal(record)">
                                    编辑
                                </a-menu-item>
                                <a-menu-item key="3" @click="deleteZl(record)">
                                    删除
                                </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                </a-table>
                </div>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if="isVisible" :cateId="item.cate_id" :item="modalData"/>
        <transfer :item='selectedRowKeys' v-if="isTransfer"/>
  </a-modal>
</template>

<script>
 const columns = [
   { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
   { title: '资料名称', dataIndex: 'file_name', key: 'file_name',scopedSlots: { customRender: 'name' },ellipsis: true,sorter:()=>{}},
   { title: '资料类型', dataIndex: 'file_ext_type', key: 'file_ext_type',align:'center',sorter:()=>{}},
   { title: '资料大小', dataIndex: 'size', key: 'size',sorter:()=>{}},
   { title: '添加时间', dataIndex: 'created_at', key: 'created_at',sorter:()=>{}},
   { title: '操作', key: 'operation', align: 'right', width:100, scopedSlots: { customRender: 'action' }}
 ]

    import editModal from './editModal'
    import transfer from './transfer'
    import C_ITEMS from '@/utils/items'
    export default {
        name:'fileList',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        props: {
            item: Object
        },
        components: {
            editModal,
            transfer
        },
        data() {
            return {
                visible: false,
                loading: false,
                isVisible: false,
                isTransfer:false,
                confirmLoading: false,
                isSortModal: false,
                list: [],
                C_ITEMS,
                modalData: null,
                columns,
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                current: 1,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                    },
                    "sort": ''
                },
                selectedRowKeys:[],
                sortIndex:-1,
            }
        },
        created () {
            this.pageParams.perPage = this.$ls.get('perPage') || 10
            this.searchParams['per-page'] = this.pageParams.perPage
            this.visible = true
            this.getList()
        },
        methods: {
            async getList(e) {
                e?e.preventDefault():''
                this.loading = true
                this.searchParams.search.cate_id = this.item.cate_id
                let res = await this.$store.dispatch('zlShareListAction',{data:this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk() {
                
            },
            handleCancel(){
                this.parent.hideFileList()
            },
            changePage(page, pageSize) {
                this.searchParams.page = page
                this.getList()
            },
            onShowSizeChange(current, size) {
                this.searchParams['per-page'] = size
                this.$ls.set('perPage',size)
                this.getList()
            },
            showEditModal(item) {
                this.modalData = item
                this.isVisible = true
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
                this.getList();
            },
            hideModal(val) {
                if(val){
                    this.getList()
                }
                this.isVisible = false
            },
            deleteZl(item) {
                let that = this
                this.$confirm({
                    title: `确定要删除资料"${item.file_name}"?`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('zlShareDeleteAction', { id: item.id })
                        if (res) {
                        that.getList()
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            onSelectChange(selectedRowKeys,record) {
                this.selectedRowKeys = selectedRowKeys
            },
            showTransfer(){
                this.isTransfer = true
            },
            hideTransfer(num){
                if(num){
                    this.selectedRowKeys = []
                    this.getList()
                    this.parent.toRefresh()
                }
                this.isTransfer = false
            },
            showSort(index){
                this.isSortModal = true
                this.sortIndex = index
            },
            handleSort(e,record){
                record.file_sort = e
            },
            async handlesortOk(item){
                let obj = {
                    cate_id : this.item.cate_id,
                    id : item.id,
                    order_index : item.file_sort
                }
                this.confirmLoading = true
                await this.$store.dispatch('zlShareChangeOrderAction', obj)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.getList()
                    this.isSortModal = false
                    this.confirmLoading = false
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
            },
            hideRelatedModal(){
                this.isSortModal = false
            },
            toPreview(item){
                if(item.file_ext_type === 'pdf' || item.file_ext_type === 'mp3' || item.file_ext_type === 'mp4'){
                    let link = item.link.split('?attname')[0]
                    window.open(link)
                }else if(item.file_ext_type === 'ppt' || item.file_ext_type === 'pptx' || item.file_ext_type === 'xls' || item.file_ext_type === 'xlsx' || item.file_ext_type === 'docx'){
                    window.open(`https://view.officeapps.live.com/op/view.aspx?src=${item.link}`)
                    // window.open(`https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(item.link)}`)
                    // window.open(`http://api.idocv.com/view/url?url=${encodeURIComponent(item.link)}`)
                    
                }else{
                    this.$message.warning('该类型文件暂不支持在线预览！！')
                }
            }
        },

    }
</script>

<style lang="scss" scoped>
    .zlgl-sort{
        position: absolute;
        right:0;
        top:-12px;
        width:330px;
        background:#fff;
        padding:5px;
        z-index: 10;
    }
</style>
<template>
  <div class="common zlgx">
    <!-- <category /> -->
    <div class="c-main">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button type="primary" icon="plus" @click="showEditModal()">添加分类</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>分类名称</span>
                </template>
                <a-input v-model.trim="searchParams.search.category_name" placeholder="分类名称" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="zl-list">
       <div>
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <a-table size="middle" :pagination="false" :bordered='false' rowKey="cate_id"
              :columns="columns" :dataSource="list" @change="handleChange">

              <template slot="index" slot-scope="text, record , index">
                  <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
              </template>

              <template slot="cate_name" slot-scope="text,record">
                <a @click="showFileList(record)"><a-icon type="folder" /> {{text}}</a>
              </template>

              <template slot="count" slot-scope="text,record">
                <a @click="showFileList(record)">{{text}}</a>
              </template>

              <template slot="action" slot-scope="text,record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                    <a-menu slot="overlay">
                      <a-menu-item key="1" @click="showEditModal(record)">
                        编辑
                      </a-menu-item>
                      <a-menu-item key="2" @click="deleteZl(record)">
                        删除
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
              </template>
          </a-table>
        </div>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
    <fileList v-if="isFileList" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  { title: '分类名称', dataIndex: 'cate_name', key: 'cate_name',scopedSlots: { customRender: 'cate_name' }},
  { title: '资料数量', dataIndex: 'category_count', key: 'cate_count',scopedSlots: { customRender: 'count' },align:"center"},
  { title: '可见范围', dataIndex: 'category_auth', key: 'cate_auth',align:"center",sorter:()=>{}},
  { title: '存储容量', dataIndex: 'category_size', key: 'cate_usage',align:"center" ,sorter:()=>{}},
  { title: '添加时间', dataIndex: 'created_at', key: 'created_at',sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width:80, scopedSlots: { customRender: 'action' }}
]


import C_ITEMS from '@/utils/items'
// import category from './category/index'
import editModal from './category/editModal'
import fileList from './fileList'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'zlgx',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal,
    fileList
  },
  data() {
    return {
      loading: false,
      C_ITEMS,
      list: [],
      columns,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
        },
        "sort": ''
      },
      visible: false,
      isFileList: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ tableMixins ],
  methods: {
    async getList(e) {
      e?e.preventDefault():''
      this.loading = true
      let res = await this.$store.dispatch('zlCategoryListAction',{data:this.searchParams})
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    showEditModal(item) {
      console.log(item)
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal(val) {
      this.visible = false
      if(val){
        this.getList()
      }
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
        if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.field}`
        }else{
            this.searchParams['sort'] = `-${sorter.field}`
        }
        }else{
        this.searchParams['sort'] = ''
        }
        this.getList();
    },
    deleteZl(item) {
      let that = this
      this.$confirm({
        title: `确定要删除资料分类"${item.cate_name}"?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('zlCategoryDeleteAction', { cate_id: item.cate_id })
            if (res) {
              that.$message.success('操作成功~')
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    showFileList(item){
      this.modalData = item
      this.isFileList = true
    },
    hideFileList(){
      this.isFileList = false
    },
    toRefresh(){
      this.getList()
    }
  }
}
</script>

<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="分类名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="rules.categoryName"/>
      </a-form-item>
      <a-form-item label="分类权限" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-decorator="rules.categoryAuth">
          <a-radio v-for="(item, index) of authArr" :value="index" :key="index">{{ item }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增资料分类',

      form: this.$form.createForm(this),
      authArr: ['全员可见', '仅学员可见', '不对外可见'],
      rules: {
        categoryName: [ 'category_name',
          {rules: [{ required: true, message: '请输入分类名称!' }]}
        ],
        categoryAuth: [ 'category_auth',
          {rules: [{ required: true, message: '请选择分类权限!' }]}
        ]
      },
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    const { item, authArr } = this
    console.log(item)
    if (item) {
      this.ModalTitle = '编辑资料分类'
      this.form.setFieldsValue({
        category_name: item.cate_name,
        category_auth: authArr.indexOf(item.category_auth)
      })
    }else{
      this.form.setFieldsValue({category_auth:0})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      try {
        const { item, form } = this
        // let params = await form.validateFields((err,values)=>{
        //   if(!err){
        //     console.log(values)
        //     return values
        //   }
        // })
        // console.log(params)
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.cate_id = item.cate_id
          }
          this.confirmLoading = true
          let res = await this.$store.dispatch(item ? 'zlCategoryEditAction' : 'zlCategoryAddAction', params)
          if(res) {
            form.resetFields()
            this.parent.submitModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideModal()
    }
  }
}
</script>

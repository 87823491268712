<template>
        <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="转移" width="400px"
            @ok="handleOk" @cancel="handleCancel">
            <a-form layout="inline">
                <a-form-item label="变更文件为：">
                    <a-select placeholder="请选择" v-model="cateId" showSearch :filterOption="filterOption" style="width:240px">
                    <a-select-option v-for="(d, index) of consultants" :key="index" :value="d.cate_id">{{ d.filter_name}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
    export default {
        name:'transfer',
        inject: ['parent'],
        props:['item'],
        data() {
            return {
                visible:false,
                ModalTitle: '批量转移',
                confirmLoading:false,
                consultants:[],
                cateId:undefined
            }
        },
        created () {
            this.visible = true
            this.getConsultant()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchLibraryCategoryAction', {})
                this.consultants = res.data
            },
            async handleOk() {
                console.log(this.cateId)
                if(!this.cateId){
                    this.$message.success('请选择!!')
                    return false
                }
                let obj = {
                    move_to_cate_id:this.cateId,
                    file_id:this.item
                }
                console.log(obj)
                await this.$store.dispatch('zlShareMoveToAction', obj)
                .then(res=>{
                    this.$message.success("操作成功~")
                    this.parent.hideTransfer(1)
                })
                .catch(err=>{
                    this.$message.error("操作失败~")
                })
                
            },
            handleCancel(){
                this.parent.hideTransfer()
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
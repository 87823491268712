<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">

      <!-- <a-form-item label="资料分类" :label-col="labelCol" :wrapper-col="{ span: 6 }">
        <a-select placeholder="请选择" v-decorator="rules.cateId">
          <a-select-option v-for="(item, index) of category" :key="index" :value="item.cate_id">{{ item.filter_name }}</a-select-option>
        </a-select>
      </a-form-item> -->

      <a-form-item label="资料名称" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-input v-decorator="rules.fileName "/>
      </a-form-item>
      
      <a-form-item label="资料简介" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-textarea v-decorator="rules.fileDescription" :rows="4"/>
      </a-form-item>

      <a-form-item label="资料路径" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.filePath" name="file" 
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :fileList="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>

      <!-- <a-form-item label="有效时间" :label-col="labelCol" :wrapper-col="{ span: 6 }">
        <a-date-picker v-decorator="rules.dueTime" clear placeholder="永久"/>
      </a-form-item>
      <a-form-item label="资料标签" :label-col="labelCol" :wrapper-col="{ span: 10 }">
        <a-input placeholder="多个使用逗号(,)分开" v-decorator="rules.fileTags"/>
      </a-form-item>
      <a-form-item label="积分设置" :label-col="labelCol" :wrapper-col="{ span: 6 }">
        <a-input v-decorator="rules.integral"/>
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    cateId:Number
  },

  computed: mapState({
    category: state => state.market.category
  }),

  data() {
    return {
      labelCol: { span: 5 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增资料',

      form: this.$form.createForm(this),
      authArr: ['全员可见', '仅学员可见', '不对外可见'],
      rules: {
        // cateId: [ 'cate_id',
        //   {rules: [{ required: true, message: '请选择资料分类!' }]}
        // ],
        fileName: [ 'file_name',
          {rules: [{ required: true, message: '请输入资料名称!' }]}
        ],
        filePath: [ 'file_path',
          {rules: [{ required: true, message: '请上传资料!' }]}
        ],
        fileDescription: [ 'file_description',
          // {rules: [{ required: true, message: '请输入资料简介!' }]}
        ],
        dueTime: [ 'due_time' ],
        fileTags: [ 'file_tags' ],
        integral: [ 'integral' ],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: []
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    let { item, authArr } = this
    if (item) {
      this.ModalTitle = '编辑资料'
      let res = await this.$store.dispatch('zlShareDetailAction', { share_id: item.id })
      if (res) {
        item = res.data
        this.form.setFieldsValue({
          file_name: item.file_name,
          file_path: item.file_path,
          file_description: item.file_description,
          due_time: item.due_time,
          file_tags: item.file_tags,
          integral: item.integral,
        })
        if (item.file_path) {
          this.fileList[0] = {
            uid: '1',
            name: item.file_path,
            status: 'done',
          }
        }
      }
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if(this.fileList[0].status != 'done'){
          this.$message.warning('请等待文件上传!!')
          return false
        }
        this.confirmLoading = true
        if (params) {
          if (item) {
            params.id = item.id
            params.share_id = item.id
          } 
          params.file_path = this.fileList[0].name
          params.size = this.fileList[0].size
          params.cate_id = this.cateId
          await this.$store.dispatch(item ? 'zlShareEditAction' : 'zlShareAddAction', params)
          .then(res=>{
            this.$message.success('操作成功~')
            form.resetFields()
            this.parent.hideModal(1)
          })
          .catch(err=>{
            this.$message.success('操作失败~')
            this.confirmLoading = false
          })
        }
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideModal()
    },

    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
      if(file.fileList[0].status != 'done'){
        this.fileList = file.fileList
      }else{
        this.fileList[0] = {
          uid: '1',
          name: this.uploadParams.key,
          status: 'done',
          size:file.file.size
        }
        this.fileList[0].name = this.uploadParams.key
      }
    }
  }
}
</script>
